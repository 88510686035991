@tailwind base;
@tailwind components;
@tailwind utilities;

.my-container {
    width: min(100% - 30px, 1380px);
    margin-inline: auto;
}

.next-slick-arrow,
.prev-slick-arrow {
color: #000000;
font-size: 45px;
}

.slick-slide > div {
  margin: 0 4px;
}

.slick-list {
  margin: 0 -4px;
}

.dropdown-container {
    width: min(100vw - 30px, 1380px);
    margin-inline: auto;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
